import React from "react";

import "./footer.scss";

const Footer = () => (
  <footer className="footer" role="contentinfo">
    <div className="grid">
      <p>
        © 2019 Marine Contractors Ltd • Registered in England &amp; Wales No.
        08840489 • Registered Office: 7 Quay View Business Park, Lowestoft,
        Suffolk, NR32 2HD
      </p>
    </div>
  </footer>
);

export default Footer;
