import PropTypes from "prop-types";
import React from "react";

import "./header.scss";

import Logo from "../images/logo.png";

const Header = ({ siteTitle }) => (
  <header className="header" role="banner">
    <div
      className="grid"
      itemscope=""
      itemtype="http://schema.org/Organization"
    >
      <a className="logo" itemprop="url" href="https://marconltd.co.uk">
        <img src={Logo} alt="Marine Contractors Ltd" itemprop="logo" />
      </a>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
